<template>
  <div class="childContainer mediaLeft">
    <div class="mediaContainer">
      <img class="media" src="../../../assets/inspiration/inspi1.jpg" alt="" />
    </div>
    <div class="textContainer">
      <div class="content">
        <h2>Une émotion :</h2>
        <p>
          C'est de là que part l'inspiration. Qu'elle soit provoquée par un
          paysage, une chanson ou un baiser. SUN Jewelry souhaite retranscrire
          ces sensations dans ses bijoux et inviter son futur porteur à se
          laisser bercer par son histoire.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped lang="scss">
</style>